import { Link } from "gatsby"
import React from "react"

import { motion } from "framer-motion"

import style from "./articlePreviewR.module.scss"

export const ArticlePreviewR = ({
  title,
  description,
  tags,
  date,
  slug,
  ...props
}) => {
  return (
    <motion.div
      className={style[`container`]}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        ease: "easeInOut",
        duration: 0.3,
        restSpeed: 0,
        restDelta: 0,
      }}
    >
      <Link to={`/blog/${slug}`} className={style[`link`]}>
        <h2 className={style[`title`]}>{title}</h2>
        <p className={style["description"]}>{description}</p>
        <ul className={style[`tags`]}>
          {tags.map(tag => (
            <li>{tag}</li>
          ))}
        </ul>
        <date className={style[`date`]}>{date}</date>
      </Link>
      <div className = {style.shadow}></div>
    </motion.div>
  )
}

ArticlePreviewR.defaultProps = {
  title: "Human driven",
  description:
    "The @media rule is used in media queries to apply different styles for different media types/devices.",
  tags: ["veerkracht", "continuiteit", "business"],
  date: "13 februari 2021",
}
