import React, { useState, useEffect } from "react"
// import { Link } from "gatsby"

import Layout from "../layouts/layout"
import SEO from "../components/seo"
// import ArticlePreview from "../components/articlePreview"
import { ArticlePreviewR } from "../components/articlePreviewR"

import WhiteForm from "../components/whiteForm"

import blogStyle from "./blog.module.scss"
// import { contentful } from "contentful"

const contentful = require("contentful")
const client = contentful.createClient({
  space: "11avwnkjhfen",
  accessToken: "4p7BDuQOiSmTYEmTFCDw1_rMXVwcHB-x3IhCvKz8ulM",
})

function Blog({ location }) {
  const [blogposts, setblogposts] = useState()

  async function getBlogPosts() {
    await client
      .getEntries({ content_type: "blogPost" , order: '-sys.createdAt' })
      .then(item => setblogposts(item))
    // return entries.items
  }
  useEffect(() => {
    // const [author] = getPeople()
    // const blogPosts = getBlogPosts()
    window.scrollTo(0, 0)
    // console.log(client)

    getBlogPosts()
    // const items = getBlogPosts().then.setblogposts(items)
  }, [])

  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }
  return (
    <Layout loc={location}>
      <SEO title="Blog" />
      {/* {console.log(blogposts)} */}

      <main className={blogStyle.grid}>
        {/* <div className={blogStyle.formWrapper}>
          <WhiteForm></WhiteForm>
        </div> */}
        {blogposts &&
          blogposts.items.map((item, index) => (
            // console.log(new Date(item.fields.publishDate).toDateString())

            <ArticlePreviewR
              title={item.fields.title}
              description={item.fields.description}
              tags={item.fields.tags}
              date={new Date(item.fields.publishDate).toLocaleDateString(
                "nl-NL",
                dateOptions
              )}
              slug={item.fields.slug}
            ></ArticlePreviewR>
          ))}
      </main>
    </Layout>
  )
}

export default Blog
